<template>
  <b-row class="match-height">
    <b-col lg="12">

      <b-card title="Add Old Asset">

        <b-form @submit.prevent="submitNewAsset">
          <b-row>

            <b-col cols="2">

<b-button
  type="button"
  variant="primary"
  class="mr-1"
  @click="previous()"
>
  <feather-icon
    icon="ArrowLeftIcon"
    size="16"
    class="align-middle"
  />
  Back
</b-button>
<br />
<br />
<br />
</b-col>
            <b-col cols="12">
              <b-form-group label="Item *" label-for="chosenItem">
                <Select2 v-model="chosenItem" :settings="settings" @select="putItem($event)" required/>
              </b-form-group>
              <b-form-group label="Ref ID *" label-for="refId" label-cols-md="12">
                <b-form-input v-model="refId" placeholder="Ref ID" required/>
              </b-form-group>
              <b-form-group label="Local ID " label-for="localId" label-cols-md="12">
                <b-form-input v-model="localId" placeholder="Local ID" />
              </b-form-group>
              <b-form-group label="Group Asset *" label-for="category" label-cols-md="12">
                <v-select-new v-model="assetCategory" :options="categories" required/>
              </b-form-group>
              <b-form-group label="Description *" label-for="description" label-cols-md="12">
                <b-form-input v-model="assetDescription" placeholder="Asset Description" required/>
              </b-form-group>
              <b-form-group label="Note " label-for="note" label-cols-md="12">
                <b-form-input v-model="assetNote" placeholder="Asset Note" required/>
              </b-form-group>
              <b-form-group label="GPS" label-for="gps" label-cols-md="12">
                <b-form-input v-model="assetGPS" placeholder="Asset GPS" />
              </b-form-group>
              <b-form-group label="Date of Acquisition *" label-cols-md="12">
                <b-form-datepicker v-model="assetDateOfAcquisition" placeholder="Asset Date of Acquisition" required/>
              </b-form-group>
              <b-form-group label="Depreciation Method *" label-for="assetDepreciationMethod" label-cols-md="12">
                <v-select-new v-model="assetDepreciationMethod" :options="methods" required/>
              </b-form-group><b-form-group label="Acquisition Value *" label-for="assetAcquisitionValue" label-cols-md="12">
  <input
    v-model="assetAcquisitionValue"
    placeholder="Asset Acquisition Value"
    required
    class="form-control"
    @input="handleInput($event,'assetAcquisitionValue')"
  />
</b-form-group>

<b-form-group label="Residual Value" label-for="assetResidualValue" label-cols-md="12">
  <input
    v-model="assetResidualValue"
    placeholder="Asset Residual Value"
    class="form-control"
    @input="handleInput($event,'assetResidualValue')"
  />
</b-form-group>

<b-form-group label="Market Value" label-for="assetMarketValue" label-cols-md="12">
  <input
    v-model="assetMarketValue"
    placeholder="Asset Market Value"
    class="form-control"
    @input="handleInput($event,'assetMarketValue')"
  />
</b-form-group>
              <b-form-group label="Asset Condition *" label-for="assetCondition" label-cols-md="12">
                <v-select-new v-model="assetCondition" :options="conditions" required/>
              </b-form-group>
              <b-form-group label="Asset Address *" label-for="assetAddress" label-cols-md="12">
                <b-form-input v-model="assetAddress" placeholder="Asset Address" required/>
              </b-form-group>
              <b-form-group label="Asset Responsible *" label-for="assetResponsible" label-cols-md="12">
                <b-form-input v-model="assetResponsible" placeholder="Asset Responsible" required/>
              </b-form-group>
              <b-form-group label="Serial Number " label-for="assetSerialNumber" label-cols-md="12">
                <b-form-input v-model="assetSerialNumber" placeholder="Asset Serial Number" />
              </b-form-group>
              <b-form-group label="Warranty" label-for="assetWarranty" label-cols-md="12">
                <b-form-input v-model="assetWarranty" placeholder="Asset Warranty" />
              </b-form-group>
              <b-form-group label="Document" label-for="assetDocument" label-cols-md="12">
                <b-form-file
                  ref="uploadFile"
                  placeholder="Choose one or more files or drop it here..."
                  drop-placeholder="Drop files here..."
                  type="file"
                  @change="handleFilesUploadDocument"
                />
              </b-form-group>
              <b-form-group label="Photo *" label-for="assetPhoto" label-cols-md="12">
                <b-form-file
                  ref="uploadFilePhoto"
                  placeholder="Choose one file or drop it here."
                  drop-placeholder="Drop a file here..."
                  type="file"
                  @change="handleFilesUploadPhoto"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <!-- submit button -->
          <b-button type="submit" variant="primary" :disabled="submitButtonDisabled">
            Submit
          </b-button>
        </b-form>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
  BListGroup,
  BListGroupItem,
  BFormDatepicker,
  BFormFile,
} from "bootstrap-vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import axios from "axios"
import moment from "moment"
import Ripple from "vue-ripple-directive"
import Select2 from 'v-select2-component'

import { ref } from "@vue/composition-api"

const photoFile = ""
const documentFile = ""
const VersionCheck = ""
const items = []
const chosenItem = ref(3)
const refId = "" // Assuming refId is a string
const localId = "" // Assuming localId is a string
const assetCategory = "" // Assuming assetCategory is a string
const assetDescription = "" // Assuming assetDescription is a string
const assetGPS = "" // Assuming assetGPS is a string
const assetDateOfAcquisition = "" // Assuming assetDateOfAcquisition is a string
const assetAcquisitionValue = "" // Assuming assetAcquisitionValue is a string
const assetDepreciationMethod = "Metode Garis Lurus" // Assuming assetDepreciationMethod is a string
const assetResidualValue = "" // Assuming assetResidualValue is a string
const assetMarketValue = "" // Assuming assetMarketValue is a string
const assetCondition = "" // Assuming assetCondition is a string
const assetAddress = "" // Assuming assetAddress is a string
const assetResponsible = "" // Assuming assetResponsible is a string
const assetSerialNumber = "" // Assuming assetSerialNumber is a string
const assetWarranty = "" // Assuming assetWarranty is a string
const assetNote = ""
const chosens = []
const submitButtonDisabled = false
const categories = ["Machinery (MC)", "Furniture & Fixture", "Office Equipment", "Lab Equipment", "Vehicle (VH)", "Building", "Software", "Low Value Asset", "Land"]
const methods = ["Metode Garis Lurus", "Metode Saldo Menurun", "Metode Unit Produksi", "Metode Jam Kerja", "Metode Penyusutan Akselerasi"]
const conditions = ["Baik", "Sedang", "Buruk"]
export default {
  components: {
    BFormFile,
    BListGroup,
    BListGroupItem,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    Select2,
    BFormDatepicker,
  },
  directives: {
    Ripple,
  },
  data() {
    const userToken = this.$cookies.get("userToken")
    return {
    photoFile,
    documentFile,
    refId,
    localId,
    assetCategory,
    assetDescription,
    assetGPS,
    assetDateOfAcquisition,
    assetAcquisitionValue,
    assetDepreciationMethod,
    assetResidualValue,
    assetMarketValue,
    assetCondition,
    assetAddress,
    assetResponsible,
    assetSerialNumber,
    assetWarranty,
    assetNote,
      categories,
      methods,
      conditions,
      VersionCheck,
      items,
      chosenItem,
      chosens,
      readonly: false,
      submitButtonDisabled,
      settings: {
        ajax: {
          url: `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_ITEMS}`,
          beforeSend: xhr => {
            xhr.setRequestHeader('Authorization', `Bearer ${userToken}`)
          },
          data: params => {
            const query = {
              search: params.term,
              offset: 0,
              limit: 50,
            }

            // Query parameters will be ?search=[term]&type=public
            return query
          },
          processResults: response => {
            let resItems = response.data
            resItems = resItems.map(item => {
              item.text = `${item.item_code} - ${item.item_name}`
              item.id = item.item_id
              item.unitname = item.unit ? item.unit.unit_name : '-'
              return item
            })
            // Transforms the top-level key of the response object from 'items' to 'results'
            return {
              results: resItems,
            }
          },
        },
      },
    }
  },
  created() {
    window.setInterval(() => {
      this.removeReadOnly()
    }, 1000)
  },
  async mounted() {
    // await this.getItems()
    this.chosens = []
  },
  beforeMount() {},
  methods: {
    removeReadOnly() {
      const searches = document.getElementsByClassName("vs__search")
      for (let i = 0; i < searches.length; i += 1) {
        searches[i].removeAttribute("readonly")
      }
    },
    getItems(search = "") {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_ITEMS}?offset=0&limit=50search=${search}`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            const itemsJoin = response.data.data.map(elem => ({
              text: `${elem.item_code} - ${elem.item_name}`,
              id: elem.item_id,
            }))
            this.items = itemsJoin
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get Data Success",
                icon: "CheckIcon",
                variant: "success",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get Data Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Get Settings Failed",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    },
    submitNewAsset() {
  this.submitButtonDisabled = true

  const formData = new FormData()
  formData.append('asset_item_id', this.chosenItem)
  formData.append('asset_ref_id', this.refId)
  formData.append('asset_local_id', this.localId)
  formData.append('asset_category', this.assetCategory)
  formData.append('asset_description', this.assetDescription)
  formData.append('asset_gps', this.assetGPS)
  formData.append('asset_date_of_acquistion', this.assetDateOfAcquisition)
  formData.append('asset_acquistion_value', this.assetAcquisitionValue)
  formData.append('asset_depreciation_method', this.assetDepreciationMethod)
  formData.append('asset_residual_value', this.assetResidualValue)
  formData.append('asset_market_value', this.assetMarketValue)
  formData.append('asset_condition', this.assetCondition)
  formData.append('asset_address', this.assetAddress)
  formData.append('asset_responsible', this.assetResponsible)
  formData.append('asset_serial_number', this.assetSerialNumber)
  formData.append('asset_note', this.assetNote)
  formData.append('asset_warranty', this.assetWarranty)
  formData.append('time', moment())

  if (typeof this.documentFile[0] !== "undefined") {
  formData.append("document", this.documentFile[0], this.documentFile[0].name)
}
if (typeof this.photoFile[0] !== "undefined") {
formData.append("photo", this.photoFile[0], this.photoFile[0].name)
}

    const userToken = this.$cookies.get('userToken')
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${userToken}`,
    }

    axios
      .post(
        `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CREATE_ASSET}`,
        formData,
        { headers },
      )
      .then(response => {
        if (response.data.success === true) {
          this.refId = ""
          this.assetCategory = ""
          this.assetDescription = ""
          this.assetGPS = ""
          this.assetDateOfAcquisition = ""
          this.assetAcquisitionValue = ""
          this.assetDepreciationMethod = "Metode Garis Lurus"
          this.assetResidualValue = ""
          this.assetMarketValue = ""
          this.assetCondition = ""
          this.assetAddress = ""
          this.assetResponsible = ""
          this.assetSerialNumber = ""
          this.assetWarranty = ""
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Create Asset Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.replace({ name: 'apps-procurement-assets-list' })
        } else {
          this.submitButtonDisabled = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Create Asset Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Create Asset Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
},
handleFilesUploadDocument(e) {
      this.documentFile = e.target.files || e.dataTransfer.files
    },
handleFilesUploadPhoto(e) {
      this.photoFile = e.target.files || e.dataTransfer.files
    },
    getOptionLabel(option) {
      return (option && option.label) || ""
    },
    putItem({ id }) {
      return id
    },
    applyItem(val) {
      console.log(val.data)
    },
    getOptionKey(option) {
      return (option && option.value) || ""
    },
    inputSearch(search) {
      console.log(search)
    },
    removeRow(val) {
      this.chosens = this.chosens.filter(obj => obj.value !== val)
    },
    dateFormat(value, format = "MM/DD/YYYY") {
      return moment(String(value)).format(format)
    },
    handleInput(event, field) {
      this.filterNumeric(field, event)
    },
    filterNumeric(field, event) {
      const value = event.target.value || '' // Use the event target value
      const numericValue = value.replace(/[^0-9]/g, '')
      this[field] = numericValue
    },
    previous() {
      this.$router.push({ name: "apps-procurement-assets-list" })
    },
    capitalizeWords(str) {
    // Split the string into words using spaces as the separator
    const words = str.split(' ')
    // Capitalize the first letter of each word and join them back together
    const capitalizedWords = words.map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1)
    })
    // Join the capitalized words into a single string with spaces
    return capitalizedWords.join(' ')
    },
  },
}
</script>
